<template>
  <EcxToaster />
  <GlobalLoader />
  <SpreadSheetErrorModal />
  <GlobalBanner />
  <div class="flex h-screen max-h-screen">
    <JeraSidebar v-if="route.meta.needsSidebar" />
    <main class="w-full overflow-y-scroll" id="main-view">
      <JeraToolbar v-if="route.meta.needsToolbar" />
        <RouterView
          :key="$route.path"
        />
    </main>
  </div>
</template>

<script setup lang="ts">
  import { RouterView, useRoute, useRouter } from 'vue-router';
  import { storeToRefs } from 'pinia';
  import { watch } from 'vue';
  import { useSessionStore } from '@/stores/session';
  import GlobalLoader from './components/GlobalLoader.vue';
  import GlobalBanner from './components/GlobalBanner.vue';
  import EcxToaster from './components/EcxToaster.vue';
  import JeraToolbar from './components/navigation/JeraToolbar.vue';
  import JeraSidebar from './components/navigation/JeraSidebar.vue';
  import SpreadSheetErrorModal from '@/components/EcxSpreadSheetErrorModal.vue';

  const { hasSession } = storeToRefs(useSessionStore());

  const route = useRoute();
  const router = useRouter();

  watch(() => hasSession.value, (newValue, oldValue) => {
    if (oldValue && !newValue) {
      router.push({ name: 'login' });
    }
  });
</script>

<style>
@import "@/assets/base.css";
</style>
