<template>
  <nav class="justify-between flex px-14 pt-16 pb-14 max-w-7xl mx-auto items-center gap-4">
    <div
      v-if="validateBackButton"
      class="flex items-center gap-x-4"
    >
      <button
        @click="backPage"
        class="h-12 w-12 outline outline-1 outline-border-gray rounded-lg flex items-center justify-center cursor-pointer"
      >
        <BackIcon />
      </button>
      <span class="font-medium text-subtitle-gray">
        {{ t('button.back') }}
      </span>
    </div>

    <div class="text-subtitle-gray">
      <h2
        v-if="isInitialPage && user"
      >
        {{ t('views.dashboard.subtitle', { name: user.name }) }}
      </h2>
    </div>    

    <div class="flex gap-6">
      <div
      v-if="isInitialPage && hasBusiness"
      class="flex items-center gap-4 justify-end"
    >
      <p class="text-sm font-normal text-input-outline-gray">
        {{ headOfficeInformations }}
      </p>
    </div>
      <a
        :href="helpLink"
        target="_blank"
        class="h-12 w-12 border border-gray rounded-lg flex items-center justify-center cursor-pointer"
      >
        <IconHelp />
      </a>

      <RouterLink
        :to="{ name: 'settings' }"
      >
        <button class="h-12 w-12 border border-gray rounded-lg flex items-center justify-center cursor-pointer">
          <IconSettings />
        </button>
      </RouterLink>
    </div>
  </nav>
</template>
<script setup lang="ts">
  import BackIcon from '@/assets/icons/BackIcon.vue';
  import IconSettings from '@/components/icons/IconSettings.vue';
  import IconHelp from '@/components/icons/IconHelp.vue';
  import Masks from '@/util/masks';

  import { useRouter, useRoute, RouterLink } from 'vue-router';
  import { useI18n } from '@/composables/useI18n';
  import { useBusinessStore } from '@/stores/business';
  import { useToolbarStore } from '@/stores/toolbar';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';
  import { useUserStore } from '@/stores/user';

  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const toolbarStore = useToolbarStore();
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const { getHeadOffice, hasBusiness } = storeToRefs(useBusinessStore());

  const validateBackButton = computed(() => route.name !== 'tela-inicial');
  const isInitialPage = computed(() => route.name === 'tela-inicial');

  const headOfficeInformations = computed(() => `${getHeadOffice.value?.legal_name} / ${t('toolbar.business.document')} ${Masks.cnpj(getHeadOffice.value!.cnpj)}`);
  const helpLink = import.meta.env.VITE_HELP_URL;

  const backPage = () => {
    if (toolbarStore.backPage) {
      toolbarStore.backPage();
      toolbarStore.resetJeraToolbar();
      return;
    }

    router.back();
  };
</script>
